export default defineComponent({
  props: {
    pricePlan: {
      type: Object,
      required: true
    },
    isCurrent: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      features: [{
        text: this.$t('plan.features.mix_signatures.text'),
        tooltip: this.$t('plan.features.qualities_tooltip')
      }]
    };
  }
});