import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.starts-with.js";
import useUserPlanInfo from '@/composables/useUserPlanInfo';
export default defineComponent({
  props: {
    currency: {
      type: String,
      required: true
    },
    pricePlan: {
      type: Object,
      required: true
    },
    billingCycle: {
      type: String,
      default: 'yearly'
    },
    isCurrent: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {
    var _useUserPlanInfo = useUserPlanInfo(),
      activePricePlanId = _useUserPlanInfo.activePricePlanId;
    var isTrial = computed(function () {
      var _activePricePlanId$va;
      return (_activePricePlanId$va = activePricePlanId.value) === null || _activePricePlanId$va === void 0 ? void 0 : _activePricePlanId$va.startsWith('TRIAL');
    });
    var isOldIndividualUser = computed(function () {
      return activePricePlanId.value === 'INDIVIDUAL_V1' || activePricePlanId.value === 'INDIVIDUAL_V2';
    });
    var isFreeMigrated = computed(function () {
      return activePricePlanId.value === 'free_migrated';
    });
    return {
      isTrial: isTrial,
      isOldIndividualUser: isOldIndividualUser,
      isFreeMigrated: isFreeMigrated
    };
  },
  computed: {
    billingCycleData: function billingCycleData() {
      return this.pricePlan[this.billingCycle];
    },
    displayedPrice: function displayedPrice() {
      // FIXME: Backend has inconsistent casing
      var lowercaseCurrency = this.currency.toLowerCase();
      var seatPrice = this.billingCycleData[lowercaseCurrency].seat;
      if (this.billingCycle === 'yearly') return "".concat(this.currency, " ").concat(seatPrice / 12);
      return "".concat(this.currency, " ").concat(seatPrice);
    },
    planSubtitle: function planSubtitle() {
      switch (this.pricePlan.name) {
        case 'Individual':
          return this.isFreeMigrated ? this.$t('business.plans.individual_migrated.lead') : this.$t('business.plans.individual.lead');
        case 'Team':
          return this.$t('business.plans.team.lead');
        case 'Pro':
          return this.$t('business.plans.pro.lead');
        case 'Scale':
          return this.$t('business.plans.scale.lead');
        default:
          return '';
      }
    },
    features: function features() {
      var individualFeatures = [];
      if (this.isFreeMigrated) {
        individualFeatures.push({
          text: this.$t('plan.features.ses_aes_qes_per_use.text'),
          asterisk: true
        });
        individualFeatures.push({
          text: this.$t('plan.features.no_monthly_fee.text')
        });
      } else if (this.isOldIndividualUser) {
        individualFeatures.push({
          text: this.$t('plan.features.aes_qes_per_use.text'),
          footnote: 1,
          tooltip: this.$t('plan.features.qualities_tooltip')
        });
      } else {
        individualFeatures.push({
          text: this.billingCycleData.signaturesIncluded.ses === -1 ? this.$t('plan.features.unlimited_ses.text') : "".concat(this.billingCycleData.signaturesIncluded.ses, " ").concat(this.$t('global.ses'))
        });
      }
      return {
        Individual: individualFeatures,
        Team: [{
          text: this.$t('plan.features.aes_qes_included.text', {
            count: this.billingCycleData.signaturesIncluded.aesQes
          }),
          footnote: 1,
          tooltip: this.$t('plan.features.qualities_tooltip')
        }],
        Pro: [{
          text: this.$t('plan.features.aes_qes_included.text', {
            count: this.billingCycleData.signaturesIncluded.aesQes
          }),
          footnote: 1,
          tooltip: this.$t('plan.features.qualities_tooltip')
        }]
      };
    },
    colorClass: function colorClass() {
      return "plan-".concat(this.pricePlan.name.toLowerCase());
    }
  }
});