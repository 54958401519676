import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.starts-with.js";
import CurrencySwitcher from '@/components/billing/CurrencySwitcher.vue';
import PlanCard from '@/components/business/upgrade/PlanCard.vue';
import ScalePlanCard from '@/components/business/upgrade/ScalePlanCard.vue';
import { useBusinessStore } from '@/stores/business';
import { usePaymentStore } from '@/stores/payment';
export default defineComponent({
  components: {
    CurrencySwitcher: CurrencySwitcher,
    PlanCard: PlanCard,
    ScalePlanCard: ScalePlanCard
  },
  props: {
    showCurrencySwitcher: Boolean,
    plans: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    currency: String,
    cycle: String,
    // Are we upgrading from a different paid plan?
    isPlanUpgrade: Boolean
  },
  setup: function setup(props) {
    var businessStore = useBusinessStore();
    var paymentStore = usePaymentStore();
    var userPlanInfo = useUserPlanInfo();
    var _useCurrency = useCurrency(),
      formatCurrency = _useCurrency.formatCurrency;
    var getPlanRank = function getPlanRank(planName) {
      return planName ? paymentStore.planHierarchy.indexOf(planName) : -1;
    };
    var currentPlanName = computed(function () {
      return businessStore.currentPricePlanName;
    });
    var isCancelling = computed(function () {
      return businessStore.isCancelling;
    });
    var isLocked = computed(function () {
      return businessStore.isLocked;
    });
    var isFreeMigrated = computed(function () {
      return userPlanInfo.activePricePlanId.value === 'free_migrated';
    });
    var isMigration = computed(function () {
      var _userPlanInfo$activeP;
      return (_userPlanInfo$activeP = userPlanInfo.activePricePlanId.value) === null || _userPlanInfo$activeP === void 0 ? void 0 : _userPlanInfo$activeP.includes('MIGRATED');
    });
    var individualExtraSignaturePrices = computed(function () {
      var planData = props.plans.find(function (plan) {
        return plan.id.startsWith('INDIVIDUAL');
      });
      if (!planData || !props.currency) return null;
      return {
        ses: formatCurrency(planData.monthly[props.currency.toLowerCase()].extraSes, props.currency),
        aes: formatCurrency(planData.monthly[props.currency.toLowerCase()].extraAes, props.currency),
        qes: formatCurrency(planData.monthly[props.currency.toLowerCase()].extraQes, props.currency)
      };
    });
    var displayedPlans = computed(function () {
      return props.plans.filter(function (plan) {
        if (isCancelling.value || plan.id.includes('INDIVIDUAL') && isMigration.value) return false;
        if (isLocked.value) return plan;
        return getPlanRank(currentPlanName.value) <= getPlanRank(plan.name);
      }).sort(function (planA, planB) {
        return getPlanRank(planA.name) - getPlanRank(planB.name);
      });
    });

    // Every SCALE plan is unique, so we pass data limited to what we know
    var scalePlan = {
      name: 'Scale',
      id: 'SCALE',
      seatLimit: 50
    };
    return {
      isLocked: isLocked,
      currentPlanName: currentPlanName,
      displayedPlans: displayedPlans,
      scalePlan: scalePlan,
      isFreeMigrated: isFreeMigrated,
      individualExtraSignaturePrices: individualExtraSignaturePrices
    };
  },
  computed: {
    selectedCurrency: {
      get: function get() {
        var _this$currency;
        return (_this$currency = this.currency) !== null && _this$currency !== void 0 ? _this$currency : 'EUR';
      },
      set: function set(currency) {
        this.$emit('choose', {
          currency: currency
        });
      }
    },
    billingCycle: {
      get: function get() {
        var _this$cycle;
        return (_this$cycle = this.cycle) !== null && _this$cycle !== void 0 ? _this$cycle : 'yearly';
      },
      set: function set(cycle) {
        this.$emit('choose', {
          cycle: cycle
        });
      }
    }
  },
  methods: {
    choosePlan: function choosePlan(plan) {
      this.$emit('choose', {
        plan: plan,
        currency: this.selectedCurrency,
        cycle: this.billingCycle
      });
    }
  }
});