import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    code: String
  },
  data: function data() {
    return {
      keys: {
        _comment: 'billing.settings.cc.error._comment',
        card_declined: 'billing.settings.cc.error.card_declined',
        confirmation: 'billing.settings.cc.error.confirmation',
        expired_card: 'billing.settings.cc.error.expired_card',
        general: 'billing.settings.cc.error.general',
        incorrect_cvc: 'billing.settings.cc.error.incorrect_cvc',
        incorrect_number: 'billing.settings.cc.error.incorrect_number',
        incorrect_zip: 'billing.settings.cc.error.incorrect_zip',
        invalid_cvc: 'billing.settings.cc.error.invalid_cvc',
        invalid_expiry_month: 'billing.settings.cc.error.invalid_expiry_month',
        invalid_expiry_year: 'billing.settings.cc.error.invalid_expiry_year',
        invalid_number: 'billing.settings.cc.error.invalid_number',
        processing_error: 'billing.settings.cc.error.processing_error',
        setup: 'billing.settings.cc.error.setup'
      }
    };
  },
  computed: {
    messageKey: function messageKey() {
      return this.code && this.keys[this.code] ? this.keys[this.code] : 'billing.settings.cc.error.setup';
    }
  },
  watch: {
    messageKey: function messageKey() {
      this.$emit('onErrorMessage', this.messageKey);
    }
  }
});