import { render, staticRenderFns } from "./PaymentElement.vue?vue&type=template&id=ccfbee76"
import script from "./PaymentElement.vue?vue&type=script&lang=ts&setup=true"
export * from "./PaymentElement.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./PaymentElement.vue?vue&type=style&index=0&id=ccfbee76&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports