<template>
  <div>
    <p v-if="title" class="font-weight-bold skribbleu--text mb-4">
      {{ title }}
    </p>
    <v-text-field
      v-model="vatNumber"
      filled
      hide-details
      :label="$t('global.vat_number.label')"
      :disabled="isInputDisabled"
    />
    <v-checkbox v-model="isNoReverseChargeRequested" :label="$t('input.vat.no_reverse_charge')" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isNoReverseChargeRequested: false,
    }
  },
  computed: {
    isInputDisabled(): boolean {
      return this.isNoReverseChargeRequested
    },
    vatNumber: {
      get(): string {
        return this.value
      },
      set(newValue: string) {
        this.$emit('input', newValue)
      },
    },
  },
  watch: {
    isInputDisabled: {
      handler(isDisabled: boolean) {
        this.$emit('disable', isDisabled)
        if (isDisabled) {
          this.$emit('input', '')
        }
      },
      immediate: true,
    },
  },
})
</script>

<style lang="sass" scoped>
.country-info
  display: flex
  align-items: center
  font-size: 0.75em
  * + *
    margin-left: 0.5rem
</style>
